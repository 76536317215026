.tile {
    margin-bottom: 50px;
    img {
        width: 72px;
        height: 57px;
    }

    a {
        color: initial;
    }

    &__title {
        font-size: 20px !important;
        margin: 15px 0;
        font-weight: 100;
        font-family: 'Open Sans Light';
    }

    &__desc {
        font-size: 14px;
    }

    &__text {
        font-size: 16px;
        color: #868686;
    }

    &__featuredBox {
        width: 158px;
        height: 90px;
        border-radius: 2px;
        padding: 20px;
        margin: 20px auto 30px;
        box-sizing: border-box;
        border: solid 2px #ffc303;
        background-color: #fff0c1;
    }

    &__list ul {
        margin: 0;
        padding: 0;
        li {
            font-size: 14px;
            line-height: 1.9;
            text-align: left;
            padding-left: 30px;
            margin-bottom: 30px;
            list-style-type: none;
            background: url('../../images//green-bullet.svg') no-repeat left 5px;
        }
    }

    &__notice {
        text-align: center;
        font-size: 14px;
        font-family: 'Open Sans SemiBold';
        line-height: 14px;
        font-weight: 900;
        margin-bottom: 30px;
    }

    &__special {
        min-height: 640px;
        @media only screen and (min-width: 767px) {
            min-height: 590px;
        }
    }
}
