.alt-img {
    background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('https://avcr-email.s3.amazonaws.com/confirm_header.png') !important;
    background-position:center top !important;
}
.header {
    height: 590px;
    background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.44) 47%, rgba(0, 0, 0, 0.59)), url('https://avcr-email.s3.amazonaws.com/rtv_header.png') no-repeat;
    background-position: top right;
    background-size: cover;
    z-index: 1;
    &.slim {
        height: 500px;

        .header__text {
            padding-top: 5px;
            margin-top: 20px;
        }
    }
    &.login {
        background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('https://avcr-email.s3.amazonaws.com/payments.png') !important;
        background-position: center top !important;
    }
    .membershipsPage & {
        background: linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.44) 47%, rgba(0, 0, 0, 0.59)), url('https://avcr-email.s3.amazonaws.com/rtv_header.png') no-repeat;
        background-position: left left !important;
        background-size: cover;
    }

    .signin &, .passwordReset &, .dashboard & {
        height: 400px;
        background-size: cover;
        .header__text {
            margin-top: 20px;
        }
    }
    &__wrapper {
        padding: 35px 20px 0 20px;
        display: flex;
        justify-content: space-between;
    }
    .main-logo img {
        width: 140px;
        height: 55px;
    }
    .rightNav__wrapper {
        width: 26px;
        display: flex;
        justify-content: space-between;
    }
    @media only screen and (min-width: 767px) {
        
        height: 490px;
        background-position:center center !important;

        &__wrapper {
            width: 70%;
            margin: 0 auto;
        }
    }
    
    @media only screen and (min-width: 1023px) {
        
        height: 700px;
        background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('https://avcr-email.s3.amazonaws.com/rtv_header.png');
        background-size: cover;
        
        .membershipsPage & {
            background: linear-gradient(rgba(255, 255, 255, 0), rgba(0, 0, 0, 0.44) 47%, rgba(0, 0, 0, 0.59)), url('../../images/herobg-memberships.jpg') no-repeat center center;
            background-size: cover;
        }
        .main-logo img {
            width: 190px;
            height: 70px;
        }
        &__wrapper {
            width: 100%;
            max-width: 1200px;
        }
        .mobileNav {
            display: none;
        }
        .rightNav__wrapper {
            width: auto;
        }
    }

    // Hero styles
    .header__text {
        color: #FFF;
        text-align: left;
        padding: 35px 20px 0 20px;
        
        &-leadIn {
            font-family: 'Open Sans Bold';
            font-size: 20px;
        }
        &-heading {
            font-size: 38px;
            margin-top: 15px;
            font-weight: 300;
            line-height: 45px;
        }
        &-copy {
            font-size: 22px;
            line-height: 28px;
        }
        &-button {
            font-size: 18px;
            margin-right: 5px;
            text-align: center;
            vertical-align: text-top;
            .call-us {
                margin-right: 5px;
            }
          }
        @media only screen and (min-width: 344px) {
            &-leadIn {
                font-size: 22px;
            }
            &-heading {
                margin-bottom: 20px;
            }
            &-copy {
                margin-bottom: 30px;
            }
        }
        
        @media only screen and (min-width: 767px) {
            width: 70%;
            margin: 0 auto;
        }
        
        @media only screen and (min-width: 1023px) {
            width: 90%;
            margin-top: 70px;
            max-width: 1200px;
            &-leadIn, &-heading, &-copy {
                width: 80%;
            }
            &-leadIn {
                font-size: 20px;
                font-family: 'Open Sans Regular';
            }
            &-heading {
                font-size: 50px;
                line-height: 55px;
            }
        }
    }
}










