.spinner {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba($color: #161616, $alpha: 0.9);
    z-index: 1000;

    &__wrapper {
        height: 60px;
        width: 60px;
        margin-left: auto;
        margin-right: auto;
        margin-top: 40vh;
    }
}