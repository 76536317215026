@font-face {
    font-family: "Open Sans Regular";
    src: url("/fonts/OpenSans-Regular.ttf");
}

@font-face {
    font-family: "Open Sans Light";
    src: url("/fonts/OpenSans-Light.ttf");
}

@font-face {
    font-family: "Open Sans Bold";
    src: url("/fonts/OpenSans-Bold.ttf");
}

@font-face {
    font-family: "Open Sans SemiBold";
    src: url("/fonts/OpenSans-Semibold.ttf");
}

body,
html {
    font-family: "Open Sans Light";
    max-width: 1440px;
    margin: 0 auto;
    box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
    margin: 0;
}

p {
    margin-bottom: 20px;
}

a {
    text-decoration: none;
}

input,
textarea {
    border: 1px solid #fff;
    padding: 15px;
    display: block;
    border-radius: 6px;
    box-sizing: border-box;
}

a.button,
.cta {
    border: none;
    color: #000;
    cursor: pointer;
    font-size: 14px;
    border-radius: 6px;
    padding: 20px 15px;
    display: inline-block;
    background: #ffc400;
    box-sizing: border-box;
    font-family: "Open Sans Regular";
    &.phone {
        font-size: 12px;
        color: #161616;
        font-weight: 900;

        @media only screen and (min-width: 344px) {
            font-size: 15px;
        }

        @media only screen and (min-width: 767px) {
            font-size: 16px;
        }

        @media only screen and (min-width: 1023px) {
            font-size: 17px;
        }
    }
    &:hover {
        background: #ff9e00;
        color: white;
    }
}

button.cta {
    font-size: 16px;
    padding: 20px 30px;
}

input::placeholder,
textarea::placeholder {
    color: #9b9b9b;
}

.slick-dots {
    bottom: 35px;
    li {
        margin: 0 2px;
        &.slick-active button:before {
            color: #ffc303;
        }
        button:before {
            font-size: 13px;
            color: #ffc303;
        }
    }
}

.swal2-confirm {
    background-color: #ffc303 !important;
    color: #161616 !important;
    min-width: 150px;
}

.error {
    color: #C11B1B;
    margin: 20px 0;
}

.terms {
    padding: 20px;
    text-align: center;
    .react-pdf__Page__canvas {
        margin-left: auto !important;
        margin-right: auto !important;
    }
    .react-pdf__Page__textContent {
        span {
            font-size: 18px !important;
        }
    }
}