.featuredBox {
    width: 92%;
    max-width: 1160px;
    background: #FFF;
    text-align: center;
    padding: 31px 40px;
    box-sizing: border-box;
    margin: 50px auto 50px;
    box-shadow: 5px 5px 17px #CCC;
    
    &__heading {
        font-size: 24px;
        margin-bottom: 40px;
        font-family: 'Open Sans SemiBold';
    }
    
    .tile__title {
        font-size: 18px;
        font-family: 'Open Sans SemiBold';
    }
    .tile__text {
        font-size: 14px;
        color: #4a4a4a;
    }
    &__button .cta {
        width: 221px;
        font-size: 16px;
        box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2);
    }

    &.notched {
        margin-top: -50px;
    }

    @media only screen and (min-width: 1023px) {
        &__heading {
            font-size: 36px;
            font-family: 'Open Sans Bold';
        }
        &__items {
            gap: 12.5%;
            display: grid;
            max-width: 1200px;
            grid-template-columns: 25% 25% 25%;
        }
    }
}