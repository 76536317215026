.form {
    padding: 50px 20px 20px;
    background: #F2F2F2;
    h3 {
        font-size: 22px;
        color: #161616;
        margin-bottom: 20px;
        font-weight: bold;
        font-family: 'Open Sans Bold';
    }
    form {
        margin-top: 40px;
    }
    form input {
        width: 100%;
        font-size: 12px;
        margin-bottom: 20px;
        padding: 20px 0 35px 20px;
    }
    form textarea {
        width: 100%;
        height: 200px;
        font-size: 12px;
        margin-bottom: 15px;
    }
    form input[type='submit'] {
        color: #FFF;
        padding: 14px;
        font-size: 16px;
        font-weight: bold;
        margin-bottom: 0px;
        background: #000;
        max-width: 200px;
    }
    
    form input[type='submit']:hover {
        background: #333;
    }

    .forgot {
        display: block;
        margin-top: 15px;
    }
    @media only screen and (min-width: 344px) {
        form input[type='submit'] {
            width: 70%;
            cursor: pointer;
        }
    }
    
    @media only screen and (min-width: 767px) {
        .form__wrapper {
            width: 70%;
            margin: 0 auto;
        }
        form input[type='submit'] {
            font-size: 16px;
            padding: 16px;
        }
    }
    
    @media only screen and (min-width: 1023px) {
        .form__wrapper {
            width: 90%;
            max-width: 1200px;
        }
    }
}





