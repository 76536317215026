.tiles {
    padding: 70px 20px 0;
    &.notched {
        color: #FFF;
        padding: 20px 10px;
        background-color: #f7f7f7;
        background: linear-gradient(to bottom, #12151a 50%, #f7f7f7 50%);
        .tile {
            color: #12151a;
            text-align: center;
            margin-bottom: 20px;
            padding: 20px 20px 70px;
            background-color: #FFF;
            box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.1);
            &__title {
                font-size: 24px;
                font-weight: 600;
            }
        }
    }
    @media only screen and (min-width: 767px) {
        &__wrapper {
            width: 70%;
            margin: 0 auto;
        }
    }
    @media only screen and (min-width: 1023px) {
        &__wrapper {
            width: 90%;
            gap: 12.5%;
            display: grid;
            max-width: 1200px;
            grid-template-columns: 25% 25% 25%;
        }
        &.notched {
            .tiles__wrapper {
                gap: 2%;
                grid-template-columns: 32% 32% 32%;
            }
        }
    }
}