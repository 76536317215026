.featuredIcons {
    &__wrapper {
        width: 90%;
        margin: 0 auto;
        padding: 40px 0;
    }

    &__heading {
        color: #000;
        font-size: 24px;
        text-align: center;
        margin-bottom: 40px;
        font-family: 'Open Sans Bold';
    }

    &__grid {
        display: grid;
        margin: 0 auto;
        max-width: 400px;
        grid-row-gap: 20px;
        grid-column-gap: 8%;
        grid-template-columns: 46% 46%;

        .tile {
            color: #000;
            margin-bottom: 0;
            max-width: 175px;
            padding: 25px 15px;
            text-align: center;
            background-color: #FFF;
            &__title {
                font-size: 14px;
            }
            img {
                width: 48px;
            }
        }
    }

    &.theme-yellow {
       color: #FFF;
       background: #ffc400;
    }
    &.longText {
        .featuredIcons__grid {
            grid-template-columns: 100%;
            .tile{
                max-width: 90%;
            } 
            @media only screen and (min-width: 1023px) {
                grid-template-columns: 45% 45%;
            }
        }
    }

    @media only screen and (min-width: 1023px) {
        &__heading {
            font-size: 36px;
            margin-bottom: 50px;
        }
        &__grid {
            max-width: 1085px;
            grid-row-gap: 50px;
            grid-column-gap: 6.6%;
            grid-template-columns: 20% 20% 20% 20%; 
        }
        .tile img {
            width: 85px;
            height: 70px;
        }
        .tile__title {
            font-size: 20px;
        }
    }
}