.footer {
    color: #FFF;
    padding: 50px 0;
    background: #000;
    text-align: center;
}

.footer img {
    width: 84px;
    margin-bottom: 10px;
}

.footer .footer__phone, .footer .footer__email, .footer .footer_legend {
    font-size: 15px;
}

.footer .footer__phone {
    margin-bottom: 10px;
}

.footer .footer__email {
    margin-bottom: 20px;
}

.footer .footer_legend {
    width: 70%;
    margin: 0 auto;
}

.footer a {
    color: #FFF;
}

.footer p {
    margin: 0;
}
