.navigation {
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height:100vh;
    display: none;
    color: #FFF;
    position: fixed;
    font-weight: bold;
    background:rgba(0, 0, 0, 0.9);
    &.open {
        display: block;
    }
    ul {
        margin: 80px 0 0;
        list-style: none;
        padding: 0 0 0 25px;
        li {
            margin-bottom: 30px;
            a{
                color: #FFF;
            }
        }
    }
    &__social {
        width: 77px;
        display: flex;
        display: none;
        justify-content: space-between;
        .facebookBtn,  .twitterBtn {
            width: 35px;
            height: 35px;
            a {
                display: block;
                text-indent: -9999px;
            }
        }
        
        .facebookBtn {
            background: url('../../images/FB.svg') no-repeat center center;
            background-size: 98%;
        }
        
        .twitterBtn {
            background: url('../../images/twitter.svg') no-repeat center center;
            background-size: 98%;
        }
    }

    &__mobile {

        &-trigger {
            width: 24px;
            height: 24px;
            position: relative;
            transform: rotate(0deg);
            transition: .5s ease-in-out;
            cursor: pointer;
            margin-top: 9px;
            z-index: 2;
            span {
                display: block;
                position: absolute;
                height: 3px;
                width: 100%;
                background: #FFF;
                border-radius: 9px;
                opacity: 1;
                left: 0;
                transform: rotate(0deg);
                transition: .25s ease-in-out;
                &:nth-child(1) {
                    top: 0px;
                    transform-origin: left center;
                }
                
                &:nth-child(2) {
                    top: 6px;
                    transform-origin: left center;
                }
                
                &:nth-child(3) {
                    top: 12px;
                    transform-origin: left center;
                }
            }
            &.open {
                top: 35px;
                right: 18px;
                position: fixed;
                span:nth-child(1) {
                    transform: rotate(45deg);
                    top: -1px;
                    left: 0px;
                }
                
                span:nth-child(2) {
                    width: 0%;
                    opacity: 0;
                }
                
                span:nth-child(3) {
                    transform: rotate(-45deg);
                    top: 16px;
                    left: 0px;
                }    
            }
        }        
    }
    
    // Desktop styles
    @media only screen and (min-width: 1023px) {
        width: auto;
        height: auto;
        display: block;
        position: static;
        background: none;
        margin-right: 35px;
        text-shadow: 0 0 4px rgba(0,0,0,0.9), 0 0 25px rgba(0,0,0,0.9), 0 0 50px rgba(0,0,0,0.9), 0 0 100px rgba(0,0,0,0.9);
        &.dark  {
            text-shadow: none;
            a{
                color: #000;
            }
        }
        ul {
            margin: 10px 0 0;
            display: flex;
            li {
                margin-right: 30px;
            }
        }

        &__social { 
            display: block;
        }

        &__mobile-trigger {
            display: none;
        }
    }
}






