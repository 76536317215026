.story {
    color: #fff;
    padding: 40px 0 0;
    background: #000;
    &__image img {
        width: 100%
    }
    &__text {
        padding: 0 20px;
    }
    &__heading {
        font-size: 36px;
        em {
            clear: both;
            display: block;
            font-size: 50px;
            color: #ffc400;
            font-style: normal;
        }
    }
    @media only screen and (min-width: 767px) {
        &__wrapper {
            width: 70%;
            margin: 0 auto;
            max-width: 1200px;
        }
    }
    @media only screen and (min-width: 1023px) {
        &__wrapper {
            gap: 6%;
            width: 90%;
            display: grid;
            padding-bottom: 80px;
            grid-template-columns: 45% 45%;
        }
        &__image {
            height: 300px;
            margin-top: 40px;
            overflow: hidden;
            img {                
                width: 115%;
            }
        }
    }

    &__call {
        margin-bottom: 45px !important;
    }
}